<template>
  <div v-if="ownCompany" class="d-flex justify-space-between print-img pt-4">
    <div>
      <img
        v-if="companyName.startsWith('Bergsoe')"
        width="200px"
        :src="require('@/assets/logo.png')"
      />
      <img v-else width="200px" :src="require('@/assets/panya-logo.png')" />
    </div>
    <div class="flex-grow-1 text-center">
      <div v-if="ownCompany.thaiName" class="text-h6">
        {{ ownCompany.thaiName }}
      </div>
      <div class="text-h5">{{ ownCompany.englishName }}</div>
      <div class="text-caption" style="white-space: pre">
        {{ ownCompany.address }}
      </div>
      <div class="text-caption">
        {{ $vuetify.lang.t("$vuetify.packaging.tel") }}
        {{ ownCompany.phone1 }}
      </div>
    </div>
    <div>
      <img
        style="margin-top: 15px; margin-right: 30px"
        width="200px"
        :src="require('@/assets/bml-iso-certs.jpeg')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    companyName: process.env.VUE_APP_COMPANY_NAME,
    ownCompany: null,
  }),
  methods: {
    ...mapActions("company", ["fetchOwn"]),
  },
  async mounted() {
    await this.fetchOwn().then((ownCompany) => {
      this.ownCompany = ownCompany;
    });
  },
};
</script>

<style scoped>
div.bg-img {
  width: 150px;
  min-height: 100px;
  margin-top: -10px;
  margin-left: 30px;
  background-size: 150px;
  background-image: url("~@/assets/logo.png");
  background-position: top left;
}

div.panya-bg-img {
  width: 150px;
  min-height: 100px;
  margin-top: -10px;
  margin-left: 30px;
  background-size: 150px;
  background-image: url("~@/assets/panya-logo.png");
  background-position: top left;
}

div.iso {
  width: 150px;
  min-height: 100px;
  margin-top: 15px;
  margin-right: 30px;
  background-size: 150px;
  background-image: url("~@/assets/bml-iso-certs.jpeg");
  background-position: top right;
}

div.panya-iso {
  width: 180px;
  min-height: 100px;
  margin-top: -25px;
  margin-right: 30px;
  background-size: 180px;
  background-image: url("~@/assets/panya-iso.png");
  background-position: top right;
}

@media only screen and (max-width: 600px) {
  div.bg-img,
  div.panya-bg-img,
  div.bml-iso,
  div.panya-iso {
    width: 0px;
    background-size: 0 0;
  }
}

@media print {
  .print-img {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
</style>
